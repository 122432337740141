<template>
  <v-card
    class="mx-auto my-12 carrd"
    tile
  >

    <!-- <v-img
      height="150"
      :src="src"
    ></v-img> -->

    <v-card-title>
        <h5> <v-chip class="chiip" dark large>{{stat}}</v-chip> {{title}}</h5>
    </v-card-title>

    <!-- <v-card-actions>
      <v-btn
        color="deep-purple lighten-2"
        text
        @click="nextt"
      >
        Go to Page
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
  export default {

    name: 'StatCardOne',
    props: [
        'title',
        'src',
        'stat'
    ],
    data: () => ({
        //
    }),

    methods: {
      nextt () {
        //
      },
    },
  }
</script>

<style scoped>
.carrd:hover{
    background: #000000;
    color: antiquewhite !important;
}
.carrd h5:hover{
    color: #70f000;
}
.carrd .chiip:hover{
    background: #70f000;
}
</style>