<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      color="deep-purple accent-4"
      dark
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>
        <img height="30"
        src="https://culturesquare.com/img/cs-logo-light.7656abd1.svg"
        > CS Manager
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar>
  </v-card>
</template>

<script>
  export default {
    name: 'Navg',

    data: () => ({
      
    }),
  }
</script>