<template>
  <v-row>
    <v-col cols="12">
        <v-card elevation="0">
            <v-card-title>
                Dashboard
            </v-card-title>
            <v-card-text>
                See all that is happening inside your site at a glance
            </v-card-text>
        </v-card>
    </v-col>
    <v-col cols="12">
        <h2>USERS</h2>
        <p>Last Registered User: <v-chip>{{latestUser}}</v-chip> @ <v-chip>{{latestUserReg}}</v-chip>  -- <router-link class="rlink" to="/users"> See All Users</router-link> </p>
    </v-col>
    <v-col
        v-for="rec in userrecs"
        :key="rec.id"
        cols="12"
        sm="4"
      >
        <stat-card-one :src="rec.src" :title="rec.title" :stat="rec.stat" />
    </v-col>
    <v-col cols="12">
        <br>
        <h2>RECIPES</h2>
        <p>Last Created Recipe: <v-chip>{{latestRecipe}}</v-chip> @ <v-chip>{{latestRecipeDate}}</v-chip>  -- <router-link class="rlink" to="/recipes"> See All Recipes</router-link> </p>
    </v-col>
    <v-col
        v-for="rec in reciperecs"
        :key="rec.id"
        cols="12"
        sm="4"
      >
        <stat-card-one :src="rec.src" :title="rec.title" :stat="rec.stat" />
    </v-col>
    <v-col cols="12">
        <br>
        <h2>EVENTS</h2>
        <p>Last Created Event: <v-chip>{{latestEvent}}</v-chip> @ <v-chip>{{latestEventDate}}</v-chip>  -- <router-link class="rlink" to="/events"> See All Events</router-link> </p>
    </v-col>
    <v-col
        v-for="rec in eventrecs"
        :key="rec.id"
        cols="12"
        sm="4"
      >
        <stat-card-one :src="rec.src" :title="rec.title" :stat="rec.stat" />
    </v-col>
    <v-col cols="12">
        <br>
        <h2>LANGUAGES</h2>
        <p><router-link class="rlink" to="/courses"> See All Courses</router-link>  / <router-link class="rlink" to="/clubs"> See All Clubs</router-link> / <router-link class="rlink" to="/interpreters"> See All Interpreters</router-link> </p>
    </v-col>
    <v-col
        v-for="rec in languagerecs"
        :key="rec.id"
        cols="12"
        sm="4"
      >
        <stat-card-one :src="rec.src" :title="rec.title" :stat="rec.stat" />
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import StatCardOne from '../components/StatCardOne.vue'
export default {
  components: { StatCardOne },
    name: 'Dashboard',
    mounted() {
       if (!this.authToken) {
           this.$router.push('login')
       }
       this.platformStats()
    },
    data: () => ({
        authToken:sessionStorage.getItem('adminAuthToken') ?? 'demo',
        userrecs: [],
        latestUser: '',
        latestUserReg: '',
        latestRecipe: '',
        latestRecipeDate: '',
        latestEvent: '',
        latestEventDate: '',
        reciperecs: [],
        eventrecs: [],
        languagerecs: [],
    }),
    methods: {
        platformStats () {
            //let loader = this.$loading.show()
            axios.get(process.env.VUE_APP_BASEURL+'/stats',
            {
                headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
            })
            .then((response) => {
                this.userrecs.push({id: 1, title:'Total Users', stat:response.data.totalUsers}),
                this.userrecs.push({id: 2, title:'Verified Emails', stat:response.data.verifiedUsers}),
                this.userrecs.push({id: 3, title:'Completed Profiles', stat:response.data.profileCompleted}),
                this.userrecs.push({id: 4, title:'Business Profiles', stat:response.data.businessProfiles}),
                this.userrecs.push({id: 5, title:'Total Recipe Chefs', stat:response.data.chefTotal}),
                this.userrecs.push({id: 6, title:'Chefs for Hire', stat:response.data.chef4Hire}),
                this.userrecs.push({id: 7, title:'All Events Hosts', stat:response.data.eventHostsTotal}),
                this.userrecs.push({id: 8, title:'Active Events Hosts', stat:response.data.eventHostsActive}),
                this.userrecs.push({id: 9, title:'Active Club Owners', stat:response.data.clubOwnersActive}),
                this.userrecs.push({id: 10, title:'Active Course Instructors', stat:response.data.courseOwnersActive}),
                this.userrecs.push({id: 11, title:'Language Interpreters', stat:response.data.interpretersTotal}),
                this.userrecs.push({id: 12, title:'Onboarded Stripe', stat:response.data.onboardedStripe}),

                this.reciperecs.push({id: 1, title:'Total Recipes', stat:response.data.recipesTotal}),
                this.reciperecs.push({id: 2, title:'Paid Recipes', stat:response.data.recipesPaid}),
                this.reciperecs.push({id: 3, title:'Free Recipes', stat:response.data.recipesFree}),
                this.reciperecs.push({id: 4, title:'Recipe Downloads', stat:response.data.recipesDownloads}),
                this.reciperecs.push({id: 5, title:'Recipes Revenue', stat:'$'+response.data.recipesRevenue}),
                this.reciperecs.push({id: 6, title:'Chef Hire Revenue', stat:'$'+response.data.chefHireRevenue}),

                this.eventrecs.push({id: 1, title:'Total events', stat:response.data.eventsTotal}),
                this.eventrecs.push({id: 2, title:'Physical Events', stat:response.data.eventsPhysical}),
                this.eventrecs.push({id: 3, title:'Online Events', stat:response.data.eventsOnline}),
                this.eventrecs.push({id: 4, title:'Upcoming events', stat:response.data.eventsUpcoming}),
                this.eventrecs.push({id: 5, title:'Total Event Bookings', stat:response.data.eventsBooking}),
                this.eventrecs.push({id: 5, title:'Approved Bookings', stat:response.data.eventsBookingsApproved}),
                this.eventrecs.push({id: 6, title:'Bookings Revenue', stat:'$'+response.data.bookingsRevenue}),

                this.languagerecs.push({id: 1, title:'Total Courses', stat:response.data.coursesTotal}),
                this.languagerecs.push({id: 2, title:'Total Clubs', stat:response.data.clubsTotal}),
                this.languagerecs.push({id: 3, title:'Upcoming Courses', stat:response.data.coursesUpcoming}),
                this.languagerecs.push({id: 4, title:'Active Clubs', stat:response.data.clubsActive}),
                this.languagerecs.push({id: 5, title:'Approved Course Registrations', stat:response.data.courseRegs}),
                this.languagerecs.push({id: 6, title:'Approved Club Memberships', stat:response.data.clubMemberships}),
                this.languagerecs.push({id: 7, title:'Course Reg Revenue', stat:'$'+response.data.courseRegRevenue}),
                this.languagerecs.push({id: 8, title:'Club Revenue', stat:'$'+response.data.clubMemRevenue}),
                this.languagerecs.push({id: 9, title:'Interpreter Hire Revenue', stat:'$'+response.data.interpreterHireRevenue}),
                
                this.latestUser = response.data.latestUser,
                this.latestUserReg = response.data.latestUserReg,

                this.latestRecipe = response.data.latestRecipe,
                this.latestRecipeDate= response.data.latestRecipeDate,

                this.latestEvent = response.data.latestEvent,
                this.latestEventDate= response.data.latestEventDate
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    localStorage.removeItem('authToken')
                }
                this.$toast.error(error.response ? error.response.data.message : 'Network Error!', {
                    timeout: 5000
                });
            })
            .finally(() => {
            });
        },
    },
}
</script>