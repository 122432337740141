<template>
  <div id="app">
    <div id="nav">
      <navg/>
    </div>
    <v-container>
        <router-view/>
    </v-container>
  </div>
</template>


<script>
import Navg from './components/Navg.vue'
  export default {
  components: { Navg },
    name: 'App',

    data: () => ({
      
    }),
  }
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.rlink{
    text-decoration: none;
    font-weight: bold;
}
</style>
